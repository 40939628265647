<template>
  <div>
    <Navigation />

    <div class="py-2 px-5">
      <DashboardTitle class="mb-2 mt-1"></DashboardTitle>

      <v-row>
        <v-col cols="12" md="4">
          <BuscadorEmisiones
            :currentFilters="filtros"
            :loading="loading"
            @searchUpdated="getEmisiones"
          ></BuscadorEmisiones>
        </v-col>
        <v-col cols="8">
          <v-row dense>
            <v-col cols="12" md="6">
              <EmisionesRamoGraphic
                :emisiones="todasLasEmisiones"
                @searchUpdated="getEmisiones"
              ></EmisionesRamoGraphic>
            </v-col>
            <v-col cols="12" md="6">
              <EmisionesStatusGraphic
                :emisiones="todasLasEmisiones"
                @searchUpdated="getEmisiones"
              ></EmisionesStatusGraphic>
            </v-col>
            <v-col cols="12">
              <v-card>
                <v-toolbar dense flat dark color="primary">
                  <v-toolbar-title>
                    <span>Resultados de la búsqueda</span>
                  </v-toolbar-title>
                  <v-spacer></v-spacer>
                  <download-csv
                    v-if="puedeCrear"
                    name="Emisiones.csv"
                    :data="downloadData"
                  >
                    <v-btn color="#fff" outlined>
                      <v-icon small class="mr-2">
                        mdi-file-excel
                      </v-icon>
                      <span>Descargar</span>
                    </v-btn>
                  </download-csv>
                </v-toolbar>
                <v-card-text>
                  <TablaEmisiones
                    :loading="loading"
                    :emisiones="emisiones"
                  ></TablaEmisiones>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import BuscadorEmisiones from "./BuscadorEmisiones.vue";
import TablaEmisiones from "./TablaEmisiones.vue";
import EmisionesRamoGraphic from "./EmisionesRamo.graphic.vue";
import EmisionesStatusGraphic from "./EmisionesStatus.graphic.vue";

import Navigation from "../../../../components/Nav.vue";
import DashboardTitle from "./DashboardTitle.vue";

import { mainAxios } from "../../../../mainAxios";

export default {
  components: {
    Navigation,
    DashboardTitle,
    BuscadorEmisiones,
    TablaEmisiones,
    EmisionesRamoGraphic,
    EmisionesStatusGraphic,
  },

  data() {
    return {
      loading: false,
      requestConfig: {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      },

      rol: JSON.parse(localStorage.getItem("roles"))[0].rol,
      emisiones: [],
      todasLasEmisiones: {},
      filtros: {},

      emisionesStatus: [
        {
          id: 0,
          title: "Nueva",
        },
        {
          id: 1,
          title: "Emitida Pendiente de Pago",
        },
        {
          id: 2,
          title: "Pagada Parcial",
        },
        {
          id: 3,
          title: "Pagada Total",
        },
        {
          id: 4,
          title: "Renovada",
        },
        {
          id: 5,
          title: "Finalizada",
        },
        {
          id: 8,
          title: "Duplicada",
        },
        {
          id: 6,
          title: "Cancelada",
        },
      ],
    };
  },

  computed: {
    puedeCrear() {
      const rolesValidos = [
        "AGENTCCSPONSORS",
        "OPERADOR",
        "OPERACIONES",
        "ADMIN",
      ];
      return !!rolesValidos.find((e) => e == this.rol);
    },

    downloadData() {
      return this.emisiones.map((element) => ({
        Póliza: element.id,
        Cliente: element.clienteNombre,
        Agente: element.nombreAgente,
        "Estatus Póliza": element.status,
        "Prima Neta": element.prima_neta,
        "Inicio de vigencia": element.inicio,
        "Fin de vigencia": element.fin,
        Periodicidad: element.periodicidad,
        Ramo: element.ramo,
        Aseguradora: element.aseguradoraNombre,
        Ejecutivo: element.ejecutivoNombre,
      }));
    },
  },

  mounted() {
    this.getTodasLasEmisiones();
  },

  methods: {
    getEmisiones(body) {
      this.loading = true;
      mainAxios
        .post(`/v1/emision/list/filtros`, body, this.requestConfig)
        .then(({ data }) => {
          this.loading = false;
          if (data.length)
            this.emisiones = data.map((emision) => {
              const result = {
                ...emision,
                ...(emision.requestJson && {
                  ...JSON.parse(emision.requestJson),
                }),
                status: this.emisionesStatus.find((e) => e.id == emision.status)
                  .title,
              };
              result.inicio = this.$timezone.formatServerTimezone(
                result.inicio,
                "DD/MM/YYYY"
              );
              result.fin = this.$timezone.formatServerTimezone(
                result.fin,
                "DD/MM/YYYY"
              );

              console.log({"result": result});
              return result;
              
            });
          else this.emisiones = [];
          this.filtros = body;
        });
    },

    getTodasLasEmisiones() {
      mainAxios
        .get(`v1/emision/dashboard/data`, this.requestConfig)
        .then(({ data }) => {
          this.todasLasEmisiones = data;
        });
    },
  },
};
</script>
